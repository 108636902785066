import { generateMapByOpts } from '@/common/utils'
// 性别
const sexOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '男',
    value: 1
  },
  {
    text: '女',
    value: 2
  }
]
// 身份
const userTypeOpts = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '业主',
    value: 1
  },
  {
    text: '家属',
    value: 2
  },
  {
    text: '租客',
    value: 3
  },
  {
    text: '伙伴',
    value: 4
  },
  {
    text: '嘉宾',
    value: 5
  }
]
// 审核状态
const auditStatusOpts = [
  {
    text: '审核通过',
    value: 1
  },
  {
    text: '拒绝',
    value: 2
  }
]

const sexMap = generateMapByOpts(sexOpts)
const userTypeMap = generateMapByOpts(userTypeOpts)

export {
  sexMap,
  sexOpts,
  userTypeOpts,
  userTypeMap,
  auditStatusOpts
}
