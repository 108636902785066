<template>
  <div class="userInfoAuditList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <v-datepicker label="申请时间段" :startTime.sync="searchParams.regFromDate" :endTime.sync="searchParams.regToDate"></v-datepicker>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum"></v-input>
        <v-select label="性别" v-model="searchParams.sex" :options="sexOpts"></v-select>
        <v-select label="身份" v-model="searchParams.userType" :options="userTypeOpts"></v-select>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button text="查看原因" type="text" permission="reasonView" @click="viewRefuseReason(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL } from './api'
import { sexMap, sexOpts, userTypeOpts } from './map'
import { Dropdown, DropdownMenu, DropdownItem, Dialog } from 'element-ui'
import Vue from 'vue'
import { communityParams, tenantParams } from 'common/select2Params'
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dialog)
export default {
  name: 'UserInfoAuditList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      sexOpts,
      userTypeOpts,
      communityParams,
      tenantParams,
      searchParams: {
        inuser: '',
        sex: undefined,
        communityId: '',
        regionId: '',
        fromtDate: '',
        toDate: '',
        userState: 2
      },
      headers: [
        {
          prop: 'inuser',
          label: '姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'sex',
          label: '性别',
          formatter (row) {
            return sexMap[row.role]
          }
        },
        {
          prop: 'address',
          label: '项目住址'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'applyTime',
          label: '申请时间'
        },
        {
          prop: 'approveUserName',
          label: '审核人'
        }
      ]
    }
  },
  methods: {
    viewRefuseReason (row) {
      this.$alert(row.rejectReason, { title: '拒绝原因' })
    }
  }
}
</script>
